<template>
  <div>
    <div class="list-div mx-auto my-10 shadow p-5 bg-white rounded table-responsive">
      <b-row class="my-2">
        <b-col cols="12" md="6">
          <b-input-group>
            <DateRangePicker
              ref="picker"
              opens="right"
              v-model="dateRange"
              @update="onDateRangeUpdated"
              :minDate="minDate"
              :ranges="false"
              :linkedCalendars="true"
              :autoApply="true"
              >
              <template v-slot:input="picker">
                {{ picker.startDate | date }} ~ {{ picker.endDate | date }}
              </template>
            </DateRangePicker>
            <b-input-group-append class="mx-2">
              <b-button @click="searchOrderTask">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-table
        bordered
        outlined
        head-variant="light"
        sort-by="dateTime"
        :sortDesc="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(action)="data">
          <v-btn text color="#EB4C60" @click="gotoGoogleMap(data.item.mapUrl)" v-if="data.item.mapUrl">
            <i class="flaticon2-location"></i>
          </v-btn>
          <v-btn text color="#EB4C60" @click="gotoOrderDetail(data.item.id)">
            <i class="flaticon2-next"></i>
          </v-btn>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length > perPage"
        class="mt-5"
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </div>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/order.scss";
</style>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

import { getDateStringFromTimestamp, getDateStringFromDate, showLoading, getPriceFormat, getStatusString, getTimeString } from '../../../functions';
import { firestore } from '../../../main';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default {
  name: "OrderList",
  components: {
    KTFooterExtended,
    DateRangePicker
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    return {
      perPage: 15,
      currentPage: 1,
      fields: [
        { key: 'dateTime', label: 'Date/Time', tdClass: 'align-middle', sortable: true },
        { key: 'type', tdClass: 'align-middle', sortable: true },
        { key: 'detail', tdClass: 'align-middle', sortable: true },
        { key: 'price', tdClass: 'align-middle', sortable: true },
        { key: 'createdAt', tdClass: 'align-middle', sortable: true },
        { key: 'status', tdClass: 'align-middle', sortable: true },
        { key: 'action', tdClass: 'align-middle' }
      ],
      minDate: '2022-06-01',
      dateRange: {
        startDate: startDate,
        endDate: endDate
      },
      orderList: [],
      items: []
    }
  },
  filters: {
    date(val) {
      return val ? getDateStringFromDate(val) : ''
    }
  },
  mounted() {
    if (this.$store.state.myInfo) {
      this.searchOrderTask();
    } else {
      this.$toast.error('Please login first!')
      this.$router.push('/auth');
    }
  },
  methods: {
    onDateRangeUpdated() {
    },
    gotoGoogleMap(url) {
      window.open(url, '_blank');
    },
    gotoOrderDetail(id) {
      this.$router.push({path: `/order/${id}`});
    },
    refreshList() {
      const items = [];
      this.orderList.forEach(info => {
        let mapUrl = '';
        if (info.geoPoint && info.geoPoint.latitude) {
          if (info.geoPointUnload && info.geoPointUnload.latitude) {
            mapUrl = `https://www.google.com/maps/dir/${info.geoPoint.latitude},${info.geoPoint.longitude}/${info.geoPointUnload.latitude},${info.geoPointUnload.longitude}`;
          } else {
            mapUrl = `https://www.google.com/maps?q=${info.geoPoint.latitude},${info.geoPoint.longitude}`;
          }
        }
        let detail = '';
        if (info.type === this.ORDER_TYPE_BOX) {
          detail = info.details.name;
        } else {
          detail = `Movers x ${info.movers}, Hours x ${info.hours}, `;
          detail += info.hasTruck ? `Truck, Hours x ${info.hours}` : 'No Truck';
        }
        items.push({
          id: info.orderId,
          dateTime: info.date + (info.time ? `, ${getTimeString(info.time)}` : ''),
          type: info.type,
          detail: detail,
          address: info.address,
          price: getPriceFormat(info.totalPrice / 100, true),
          status: getStatusString(info.status),
          mapUrl: mapUrl,
          createdAt: getDateStringFromTimestamp(info.createdAt)
        });
      });
      this.items = items;
    },
    searchOrderTask() {
      const startDate = getDateStringFromDate(this.dateRange.startDate);
      const endDate = getDateStringFromDate(this.dateRange.endDate);
      const loader = showLoading(this, 1);
      const q = query(collection(firestore, 'order'), where('userId', '==', this.$store.state.myInfo.userId), where('date', '>=', startDate), where('date', '<=', endDate));
      getDocs(q).then(querySnapshot => {
        loader.hide();
        this.orderList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.status > 0) {
            this.orderList.push(data);
          }
        });
        this.refreshList();
      }).catch(error => {
        loader.hide();
        window.alert(error.code + ', ' + error.message);
      });
    }
  }
};
</script>